import React, {createElement} from 'react';

import { Typography } from '@mui/material';

import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import rehypeReact from 'rehype-react';

import Layout from 'components/layout/Layout';


const renderAst = new rehypeReact({
    createElement,
    // components: { 'gaiama-image': GaimaImage },
  }).Compiler

const PrivatePolicyTemplate = props => {
  return (
    <Layout>
        <Typography component={'span'} variant={'body2'}>
            {renderAst(props.data.page.htmlAst)}
        </Typography>

    </Layout>
  )
}



PrivatePolicyTemplate.propTypes = {
    data: PropTypes.object.isRequired
}

export default PrivatePolicyTemplate

export const query = graphql`
  query($language: String!) {
    page: markdownRemark(frontmatter: { language: { eq: $language } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`
